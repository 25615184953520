import s from '@components/common/Navbar/Navbar.module.scss'

const Star = ({ number }: any) => {
  const nrOfStars = 5
  let array: any[] = []

  let remainder = number
  for (
    var i = 0;
    remainder > 0 || (nrOfStars && array.length < nrOfStars);
    i++
  ) {
    if (remainder >= 1) {
      if (!array) {
        array = [1]
      } else {
        array.push(1)
      }
      remainder -= 1
    } else {
      if (!array && remainder) {
        array = [remainder]
      } else if (remainder) {
        array.push(remainder)
      } else {
        array.push(null)
      }
      remainder -= remainder
    }
  }

  return (
    <>
      {array.map((rating: number, i: number) => {
        return (
          <span key={i}>
            {/* FULL STAR */}
            {rating === 1 && (
              <>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.788 3.103c.495-1.004 1.926-1.004 2.421 0l2.358 4.777 5.273.766c1.107.161 1.549 1.522.748 2.303l-3.816 3.72.901 5.25c.19 1.103-.968 1.944-1.959 1.424l-4.716-2.48-4.715 2.48c-.99.52-2.148-.32-1.96-1.424l.901-5.25-3.815-3.72c-.801-.78-.359-2.142.748-2.303L8.43 7.88l2.358-4.777Z" />
                </svg>
              </>
            )}

            {/* EMPTY STAR */}
            {(rating === 0 || rating === null) && (
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.788 3.103c.495-1.004 1.926-1.004 2.421 0l2.358 4.777 5.273.766c1.107.161 1.549 1.522.748 2.303l-3.816 3.72.901 5.25c.19 1.103-.968 1.944-1.959 1.424l-4.716-2.48-4.715 2.48c-.99.52-2.148-.32-1.96-1.424l.901-5.25-3.815-3.72c-.801-.78-.359-2.142.748-2.303L8.43 7.88l2.358-4.777Zm1.21.936L9.74 8.615a1.35 1.35 0 0 1-1.016.738l-5.05.734 3.654 3.562c.318.31.463.757.388 1.195l-.862 5.03 4.516-2.375a1.35 1.35 0 0 1 1.257 0l4.516 2.374-.862-5.029a1.35 1.35 0 0 1 .388-1.195l3.654-3.562-5.05-.734a1.35 1.35 0 0 1-1.016-.738l-2.259-4.576Z" />
              </svg>
            )}
            {/* HALF STAR */}
            {rating !== 1 && rating !== null && (
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 2.35c-.482 0-.964.25-1.212.753L8.43 7.88l-5.273.766c-1.107.161-1.55 1.522-.748 2.303l3.815 3.72-.9 5.25c-.15.873.544 1.582 1.331 1.582.208 0 .422-.05.63-.159l4.714-2.478 4.715 2.479c.99.52 2.148-.32 1.96-1.424l-.902-5.25 3.816-3.72c.8-.78.359-2.142-.748-2.303l-5.273-.766-2.358-4.777a1.335 1.335 0 0 0-1.21-.753Zm0 14.994V4.042l2.257 4.573a1.35 1.35 0 0 0 1.016.738l5.05.734-3.654 3.562a1.35 1.35 0 0 0-.388 1.195l.862 5.03-4.516-2.375a1.35 1.35 0 0 0-.627-.155Z" />
              </svg>
            )}
          </span>
        )
      })}
    </>
  )
}

export default Star
