import 'keen-slider/keen-slider.min.css'
import Image from 'next/image'
import Link from 'next/link'
import { useKeenSlider } from 'keen-slider/react'
import { useState } from 'react'
import { useTranslation } from 'next-i18next'

const ProductGallery = ({ products }: any) => {
  const { t } = useTranslation('common')

  const animation = { duration: 20000, easing: (t: any) => t }
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    renderMode: 'performance',
    breakpoints: {
      '(min-width: 768px)': {
        slides: { perView: 2.5, spacing: 30 },
      },
      '(min-width: 1024px)': {
        slides: { perView: 3, spacing: 20 },
      },
    },
    slides: { perView: 1.5, spacing: 15 },
  })

  // if gallery is empty, do not show it
  if (!products || products?.length < 1) {
    return null
  }

  return (
    <>
      <div className="my-6 relative max-w-[960px] mx-auto">
        <div className="font-special tracking-widest text-center text-4xl uppercase mt-4 text-brand-hover">
          {t('hanferei-product-family')}
        </div>
        <div className="px-10">
          <div ref={sliderRef} className="keen-slider">
            {products.map((product: any, i: any) => {
              return (
                <div data-aos="fade-in" key={i} className="keen-slider__slide">
                  <a style={{ cursor: 'pointer' }} aria-label={product.name}>
                    <Link legacyBehavior href={`/shop/${product.slug}`}>
                      <div
                        className="my-6 transform-all duration-500 hover:scale-105"
                        key={i}
                      >
                        <Image
                          className="object-contain aspect-square"
                          src={product.images[0]?.url}
                          alt={product.name || 'Product Image'}
                          width={480}
                          height={480}
                          priority={i === 0}
                          quality="85"
                        />
                        <div className="left-0 bg-white shadow-lg relative z-10 rounded-b-xl p-2 w-full text-center">
                          <h4 className="line-clamp-2 line-height-1 text-brand font-special text-2xl md:text-4xl px-3 pt-3 pb-2">
                            {product.name}
                          </h4>
                          <div className="text-sm text-accent-3">
                            {product.price.currencyCode === 'EUR' ? '€ ' : 'EUR '}
                            {`${(
                              Math.round(product.price.value * 100) / 100
                            ).toFixed(2)}`}{' '}
                            {product.variants[0].listPrice &&
                            typeof product.variants[0].listPrice === 'number' &&
                            product.variants[0].listPrice !==
                              product.price.value ? (
                              <span
                                className="text-accent-3 mt-1 ml-2 bg-primary"
                                style={{ textDecoration: 'line-through' }}
                              >
                                {(
                                  Math.round(
                                    product.variants[0].listPrice * 100
                                  ) / 100
                                ).toFixed(2)}{' '}
                                {product.price.currencyCode}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </a>
                </div>
              )
            })}
          </div>
        </div>
        {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e: { stopPropagation: () => any }) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e: { stopPropagation: () => any }) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length -
                  1 /* todo: here it should access the perview breakpoint value instead of overall length or it will never reach last slide to be disabled */
              }
            />
          </>
        )}
      </div>
    </>
  )
}

// @ts-ignore
function Arrow(props) {
  const disabled = props.disabled ? ' arrow--disabled' : ''
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? 'arrow--left' : 'arrow--right'
      } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  )
}

ProductGallery.defaultProps = {}

export default ProductGallery
