import { getStrapiMedia } from '@utils/strapi'
import delve from 'dlv'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

const Article = ({ attributes }: any) => {
  console.log("attributes.background", attributes.background);
  const { t } = useTranslation('common')
  const router = useRouter()
  return (
    <>
      <Link
        legacyBehavior
        href={`/news/${attributes.Slug}`}
        aria-label={attributes.title}
      >
        <div
          data-aos="fade-up"
          className="grid md:grid-cols-5 hover:cursor-pointer"
        >
          {attributes.image?.data?.attributes && (
            <div
              className="w-full bg-cover aspect-video md:col-span-2"
              style={{
                backgroundImage:
                  'url(' +
                    getStrapiMedia(
                      delve(attributes.image, 'data.attributes.url')
                    ) || undefined + ')',
              }}
            ></div>
          )}
          <div className={`content md:col-span-3 ${attributes.background ? attributes.background : 'bg-light'}`}>
            {/* todo: show category and timestamp
            <small className="text-brand">
              {new Date(attributes.Date).toLocaleDateString('de-de', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </small>*/}
            <small className="text-sm text-accent-5"> {
              new Date(attributes.Date).toLocaleDateString('de-de',
                {year: "numeric", month: "long", day: "numeric"})
            }</small>
            <h4 className="text-2xl">{attributes.title}</h4>
            <p className="text-sm text-accent-5 mt-2">{attributes.preview}</p>
            <div className="text-right text-sm mt-2">
              <Link legacyBehavior href={`/news/${attributes.Slug}`}>
                <a className="brand-link">{t('readmore')}</a>
              </Link>
            </div>
          </div>
        </div>
      </Link>
    </>
  )
}

Article.defaultProps = {}

export default Article
