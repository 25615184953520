
const CustomLightbox = ({slide}: any) => {
  return (
    <div className="yarl__slide yarl__slide_current yarl__flex_center">
      <img className="yarl__slide_image" draggable="false"
           sizes={slide.sizesString}
           srcSet={slide.srcSetString}
           src={slide.src}
           style={{maxWidth: "min(2000px, 100%)", maxHeight: "min(1333px, 100%)"}}></img>
      <div className="yarl__slide_captions_container yarl__slide_description_container">
        <div className="yarl__slide_description">{slide.title} | {slide.description} <b><a href={slide.url}>(download)</a></b></div>
      </div>
    </div>
  )
}

CustomLightbox.defaultProps = {};
export default CustomLightbox;
