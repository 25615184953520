import { getStrapiMedia } from '@utils/strapi'
import delve from 'dlv'
import { Button } from '@components/ui'

const Hero = ({ image, header, link }: any) => {
  return (
    <div className="mb-24 md:mb-5 relative" data-aos="fade-up">
      <div
        className="w-full bg-cover aspect-[4/3] md:aspect-video rounded-xl relative"
        style={{
          backgroundImage:
            'url(' + getStrapiMedia(delve(image, 'data.attributes.url')) ||
            undefined + ')',
        }}
      ></div>
      <div className="md:mx-0 mx-3 p-5 md:p-0 rounded-xl absolute -bottom-14 md:bottom-0 left-0 md:w-full md:h-full flex flex-col items-start justify-end bg-gradient-to-tr from-[rgba(0,0,0,1)] md:bg-gradient-to-tr from-[rgba(0,0,0,0.35)]">
        <h2 data-aos="fade-left" data-aos-delay="500" className="md:p-0 text-white text-accent-7 text-3xl md:text-4xl font-extrabold md:font-bold font-heading mb-4 md:ml-5 md:mt-0 md:text-5xl uppercase font-bold md:max-w-2xl md:drop-shadow-lg">
          {header}
        </h2>
        {
          link ? <Button
            data-aos="fade-left" data-aos-delay="500"
            className="md:-ml-4 md:mb-12 text-1xl md:text-3xl mt-2"
            size={'large'}
            Component={'a'}
            variant={'light'}
            href={link.href}
          >
            {link.label}
          </Button> : null
        }
      </div>
    </div>
  )
}

Hero.defaultProps = {}

export default Hero
