import Image from 'next/image'
import { getStrapiMedia } from '@utils/strapi'
import delve from 'dlv'
import { Text } from '@components/ui'
import { useTranslation } from 'next-i18next'
import { YouTube } from '@components/icons'
import { useState } from 'react'

const FeaturedVideo = ({ Title, Link, Description, Cover }: any) => {
  const { t } = useTranslation('common')
  let [showVideo, setShowvideo] = useState(false)
  const handleClick = (event: any) => {
    setShowvideo(true)
  }
  return (
    <div data-aos="fade-up" className="relative my-4">
      <div className="absolute -z-0 top-0 bg-accent-1 h-full md:h-1/2 w-full"></div>

      <div className="relative z-10 px-8">
        <h4 className="py-4 text-light uppercase">{t('video-special')}</h4>
        <div className="relative aspect-video">
          <div
            onClick={(e) => handleClick(e)}
            className={showVideo ? 'hidden' : ''}
          >
            <Image
              className="object-cover rounded-2xl"
              src={getStrapiMedia(delve(Cover, 'data.attributes.url')) || ''}
              alt={delve(Cover, 'data.attributes.alternativeText') || ''}
              width={1600}
              height={900}
              priority={false}
              quality="85"
            />
            <div
              onClick={(e) => handleClick(e)}
              className="opacity-70 absolute bottom-8 right-8 bg-white p-3 rounded-xl text-sm flex items-center"
            >
              <YouTube className="mr-1"></YouTube>
              {t('load-from-youtube')}
            </div>
          </div>
          <div className={showVideo ? 'aspect-video' : 'aspect-video hidden'}>
            {/* todo: set id after /embed/ to ID from youtube Link (explode link) */}
            <iframe
              className="w-full h-full rounded-2xl"
              src={Link}
              frameBorder="0"
            ></iframe>
          </div>
        </div>
        <div className="px-8 py-4 lg:w-11/12 lg:ml-auto">
          <h4 className="font-heading">{Title}</h4>
          <Text className="mb-4">{Description}</Text>
          {/*<div className="text-right">
            <a className="brand-link" href={Link}>{t("view-on-youtube")}</a>
          </div>*/}
        </div>
      </div>
    </div>
  )
}

FeaturedVideo.defaultProps = {}

export default FeaturedVideo
