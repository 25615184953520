import delve from 'dlv'
import { Header, Layout } from '@components/common'
import Head from 'next/head'
import Hero from '../components/CmsBlocks/Hero'
import Article from '../components/CmsBlocks/Article'
import FeaturedVideo from '../components/CmsBlocks/FeaturedVideo'
import Recommendation from '../components/CmsBlocks/Recommendation'
import ProductGallery from '../components/CmsBlocks/ProductGallery'
import Gallery from '@components/CmsBlocks/Gallery'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import commerce from '@lib/api/commerce'
import { productConnectionFragment } from '@framework/utils/queries/get-all-products-query'
import { normalizeProduct } from '@framework/utils'

import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { getReviews } from './reviews'
import { useKeenSlider } from 'keen-slider/react'
import { useState, useEffect } from 'react'
import s from './reviews/reviews.module.scss'
import { getStrapiMedia } from '@utils/strapi'
import { Star } from '@components/icons'
import Link from 'next/link'
import https from 'https'

export function redirectToHomepage() {
  return {
    redirect: {
      destination: `/`,
      permanent: false,
    },
  }
}

export function redirectTo404Page() {
  return {
    redirect: {
      destination: `/404`,
      permanent: false,
    },
  }
}
export function redirectTo308(url: string) {
  return {
    redirect: {
      destination: url,
      permanent: true,
    },
  }
}
export function redirectToShopifyTrackingPage(path: string, query: string) {
  const url = `https://seewinkler-hanferei.myshopify.com${path}${query}`
  return {
    redirect: {
      destination: url,
      permanent: false,
    },
  }
}
export function redirectToShop() {
  return {
    redirect: {
      destination: `/shop`,
      permanent: false,
    },
  }
}
export const fetchJson: any = (
  url: string,
  subset: string | null = null,
  rename = null
) => {
  // This allows us to bypass SSL issues if connecting to
  // an EC2 instance without valid certificates in place.
  let agent: any = null
  if (!process.env || process.env.NODE_ENV !== 'production') {
    agent = new https.Agent({
      rejectUnauthorized: false,
    })
  }

  return new Promise(async (resolve) => {
    if (!url) {
      resolve(null)
      return
    }
    /* @ts-ignore */
    const response = await fetch(url, { agent })
    let json = await response.json()
    if (subset) {
      /* @ts-ignore */
      json = delve(json, subset)
    }
    resolve(json)
  })
}

/* just a helper function to create url params
for populating fields in API response*/
export const createPopulationQuery = (elements: any[]) => {
  if (!elements) {
    return ''
  }
  const query = []
  for (let i = 0; i < elements.length; i++) {
    query.push('populate[' + i + ']=' + elements[i])
  }
  return query.join('&')
}

export async function getShopNavigation(locale: string): Promise<any> {
  const url = `${process.env.NEXT_PUBLIC_API_URL || 'http://localhost:1337'}`
  const query = createPopulationQuery([
    '*',
    'ShopNavigationColumn',
    'ShopNavigationColumn.ShopNavigationItem',
    'ShopNavigationColumn.ShopNavigationItem.Icon',
    'blocks.link',
  ])
  return fetchJson(
    url + '/api/shop-navigation?' + query + '&locale=' + locale,
    'data.attributes'
  )
}

const getProductsByCollectionQuery = /* GraphQL */ `
  query getAllProducts($first: Int = 250, $collection: String!) {
    collection(handle: $collection) {
      products(first: $first) {
        ...productConnection
      }
    }
  }

  ${productConnectionFragment}
`

async function getProductsByCollection({
  query = getProductsByCollectionQuery,
  collection,
  config: cfg,
}: any) {
  const { fetch, locale } = commerce.getConfig(cfg)
  let { data } = await fetch(
    query,
    {
      variables: { collection: collection },
    },
    {
      ...(locale && {
        headers: {
          'Accept-Language': locale,
        },
      }),
    }
  )
  if (
    !data ||
    !data.collection ||
    !data.collection.products ||
    !data.collection.products.edges
  ) {
    return {
      products: [],
    }
  }
  return {
    ...(data.collection.products.edges && {
      products: data.collection.products.edges.map(({ node }: any) => {
        return normalizeProduct(node)
      }),
    }),
  }
}

const getProductData = (
  slug: any = null,
  collection = null,
  locale: any = null
) => {
  return new Promise(async (resolve) => {
    const config = {
      locale: locale,
    }
    const preview = false
    if (!slug && !collection) {
      const { products } = await commerce.getAllProducts({
        variables: { first: 12 },
        config,
      })
      resolve(products)
    } else if (slug) {
      const { product } = await commerce.getProduct({
        variables: { slug: slug },
        config,
        preview,
      })

      resolve(product)
    } else if (collection) {
      const { products } = await getProductsByCollection({
        collection: collection,
        config,
      })
      resolve(products)
    } else {
      resolve(null)
    }
  })
}

export async function getServerSideProps(context: any) {
  try {
    const { locale } = context
    console.log("LOCALE!", locale);
    const url = `${process.env.NEXT_PUBLIC_API_URL || 'http://localhost:1337'}`
    const articles: any = await fetchJson(
      url +
        '/api/articles?populate=*&sort=Date%3Adesc&pagination[page]=1&pagination[pageSize]=5&locale=' +
        locale,
      'data'
    )

    const reviews = await getReviews(locale, 1, 10, null, false, true)

    const populationQuery = createPopulationQuery([
      '*',
      'blocks.link',
      'blocks.image',
      'blocks.Images',
      'blocks.Media',
      'blocks.Text',
      'blocks.Media.Title',
      'blocks.Media.CoverImage',
      'blocks.Media.image_gallery',
      'blocks.Products',
      'blocks.Products.Collection',
      'blocks.Media.image_gallery.data',
      'blocks.Media.image_gallery.Title',
      'blocks.Media.image_gallery.Media',
      'blocks.Media.image_gallery.Gallery',
      'blocks.Media.image_gallery.Gallery',
      'blocks.image-gallery',
      'blocks.image-gallery.Gallery',
      'blocks.Gallery',
      'blocks.imageGallery',
      'blocks.imageGallery.Gallery',
      'blocks.image-gallery',
      'blocks.image-gallery.Gallery',
      'blocks.Cover',
      'SeoComponent',
      'SeoComponent.SeoMetaRepeatableComponent',
      'SeoComponent.SeoMetaRepeatableComponent.metaImage',
      'SeoComponent.*',
      'Recommendations',
      'Recommendations.Cover',
      'Recommendations.Cover',
    ])
    const uri = '/api/homePage?' + populationQuery + '&locale=' + locale

    const { Title, Slug, SeoComponent, blocks, Recommendations }: any =
      await fetchJson(url + uri, 'data.attributes')
    const preview = false
    const config = { locale }
    if (blocks && blocks.length > 0) {

      let final_promises: any = []
      blocks.forEach((data: any) => {
        let promises = []
        switch (data.__component) {
          case 'blocks.image-gallery':
            const flickrApiKey = '3849884059e15ddd371a2300cb308d10'
            const method = 'flickr.photosets.getInfo'
            const userId = '125658326@N04'
            const extras =
              'url_sq,url_t,url_s,url_q,url_m,url_n,url_z,url_c,url_l,url_o'
            promises.push(
              new Promise(async (resolve) => {
                data.albumData = []
                Promise.all(
                  data.Gallery.map(async (x: { flickrId: string }) => {
                    // For each gallery get data such as name and cover image
                    const url =
                      'https://api.flickr.com/services/rest/?method=' +
                      method +
                      '&api_key=' +
                      flickrApiKey +
                      '&user_id=' +
                      userId +
                      '&photoset_id=' +
                      x.flickrId +
                      '&format=json&primary_photo_extras=' +
                      extras +
                      '&nojsoncallback=1'
                    const res = await fetchJson(url)
                    data.albumData.push(res)
                    resolve(null)
                  })
                ).then(() => {
                  resolve(null)
                })
              })
            )
            break
          case 'blocks.product-gallery':
            promises.push(
              new Promise(async (resolve) => {
                data.productData = await getProductData(
                  null,
                  data?.Products?.Collection,
                  locale
                )
                resolve(null)
              })
            )
            break
        }
        if (promises) {
          final_promises = [...final_promises, ...promises]
        }
      })
      await Promise.all(final_promises)
    }

    return {
      props: {
        ...(await serverSideTranslations(locale, ['common', 'home'])),
        ...(await getShopNavigation(locale)), // makes "ShopNavigationColumn" property available on page props
        Title,
        Slug,
        locale,
        seo: SeoComponent,
        blocks,
        Recommendations,
        articles,
        reviews,
      },
    }
  } catch (error) {
    console.error(error)
    return redirectTo404Page()
  }
}

export default function Home({
  Title,
  Slug,
  locale,
  seo,
  products,
  blocks,
  Recommendations,
  Reviews,
  articles,
  reviews,
}: any) {
  console.log('SEO >>>>>', seo)

  const { t } = useTranslation('common')

  const [loadedFeaturedReviewsSlider, setLoadedFeaturedReviewsSlider] =
    useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  const [featuredReviewsSliderRef, featuredReviewsInstanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoadedFeaturedReviewsSlider(true)
    },
    renderMode: 'performance',
    breakpoints: {
      '(min-width: 768px)': {
        slides: { perView: 3, spacing: 30 },
      },
      '(min-width: 1024px)': {
        slides: { perView: 3, spacing: 20 },
      },
    },
    slides: { perView: 2, spacing: 15 },
  })

  function ArrowReviews(props: {
    disabled: boolean
    left?: boolean
    onClick: (e: any) => void
  }) {
    const disabeld = props.disabled ? ' arrow--disabled' : ''
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${
          props.left ? 'arrow--left' : 'arrow--right'
        } ${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    )
  }

  return (
    <div>
      <Head>
        <>
          <title>{Title}</title>
          {seo ? (
            <meta
              name="description"
              content={seo.metaDescription}
              key="description"
            ></meta>
          ) : null}
          {seo?.SeoMetaRepeatableComponent
            ? seo.SeoMetaRepeatableComponent.map(
                ({ id, name, content, structuredData, metaImage }: any, index: any) => {
                  return <meta key={name} name={name} content={delve(metaImage, 'data.attributes.url') || content}></meta>
                }
              )
            : null}
        </>
      </Head>

      <div className="container-rg">
        <div className="w-full">
          {blocks &&
            blocks.slice(0, 3).map((block: any, index: any) => {
              return renderBlock(
                block,
                index,
                block.productData,
                block.albumData
              )
            })}
        </div>

        <div
          data-aos="fade-up"
          className="max-w-[960px] mx-auto mt-6 mb-10 grid grid-cols-2 md:grid-cols-4 rounded-xl overflow-hidden text-center"
        >
          <div className="bg-[#C2D67B] py-4 md:px-8 md:py-6">
            <Image
              src="/icons/icon-hofmanufaktur.png"
              alt=""
              className="mx-auto opacity-50"
              width={40}
              height={40}
              priority={false}
              quality="85"
            />
            <span className="uppercase text-sm opacity-60 text-accent-7 mt-3 block">
              {t('manufactured-on-site')}
            </span>
          </div>
          <div className="bg-[#CCDE85] py-4 md:px-8 md:py-6">
            <Image
              src="/icons/icon-natur.png"
              alt=""
              className="mx-auto opacity-50"
              width={40}
              height={40}
              priority={false}
              quality="85"
            />
            <span className="uppercase text-sm opacity-60 text-accent-7 mt-3 block">
              {t('only-natural-ingredients')}
            </span>
          </div>
          <div className="bg-[#D0E389] py-4 md:px-8 md:py-6">
            <Image
              src="/icons/icon-zertifikat.png"
              alt=""
              className="mx-auto opacity-50"
              width={40}
              height={40}
              priority={false}
              quality="85"
            />
            <span className="uppercase text-sm opacity-60 text-accent-7 mt-3 block">
              {t('recommended-by-doctors')}
            </span>
          </div>
          <div className="bg-[#D9E993] py-4 md:px-8 md:py-6">
            <Image
              src="/icons/icon-inhaltsstoffe.png"
              alt=""
              className="mx-auto opacity-50"
              width={40}
              height={40}
              priority={false}
              quality="85"
            />
            <span className="uppercase text-sm opacity-60 text-accent-7 mt-3 block">
              {t('no-artificial-ingredients')}
            </span>
          </div>
        </div>

        <div className="w-full">
          {blocks &&
            blocks.slice(3, blocks.length).map((block: any, index: any) => {
              return renderBlock(
                block,
                index,
                block.productData,
                block.albumData
              )
            })}
        </div>

        <div
          data-aos="fade-up"
          className="blog-list grid grid-cols-1 gap-6 max-w-[960px] mx-auto"
        >
          <div className="text-center font-special text-4xl text-brand-hover uppercase mt-4">
            {t('news')}
          </div>
          {articles &&
            articles.slice(0, 3).map((article: any, index: any) => {
              return <Article key={`article-index-${index}`} {...article} />
            })}
        </div>
      </div>
      <>
        {/*

          todo:  i dont know what this is supposed to be / do. what is a recommendation? @daniel

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 container-rg">
        {Recommendations &&
          Recommendations.slice(0, 4).map((recommendation: any, index: any) => {
            return (
              <div key={`recommendation-index-${index}`}>
                <Recommendation {...recommendation} />
              </div>
            )
          })}
      </div>

      */}
      </>

      {reviews?.data && (
        <>
          <div className="container-rg relative">
            <section className={s.featuredSlider}>
              <h1 className="text-center text-4xl font-special text-brand-hover mb-4 mt-8 uppercase">
                {t('reviews')}
              </h1>

              <div
                ref={featuredReviewsSliderRef}
                className="keen-slider max-w-[960px] mx-auto"
              >
                {reviews?.data.map(({ attributes }: any, i: any) => {
                  return (
                    <Link
                      key={i}
                      legacyBehavior
                      href={`/reviews/${attributes.Slug}`}
                    >
                    <div key={i} className="keen-slider__slide cursor-pointer">
                      {attributes.image?.data?.attributes && (
                        <div
                          className="w-full bg-cover bg-center aspect-square rounded-t-xl"
                          style={{
                            backgroundImage:
                              'url(' +
                                getStrapiMedia(
                                  delve(attributes.image, 'data.attributes.url')
                                ) || undefined + ')',
                          }}
                        ></div>
                      )}
                      <div className="shadow-lg bg-white rounded-b-xl mb-4 py-3 px-4">
                        <div className="justify-between items-center grid grid-rows-1">
                          <div className="mb-2 flex flex-nowrap text-brand float-right">
                            {/*attributes.rating*/}{' '}
                            <Star number={attributes.rating} />
                          </div>
                          <h4 className="text-md text-truncate">
                            {attributes.title}
                          </h4>
                        </div>

                        <div className="text-right text-sm mt-2">

                            <a className="brand-link">{t('readmore')}</a>
                        </div>
                      </div>
                    </div>
                    </Link>
                  )
                })}
              </div>

              {loadedFeaturedReviewsSlider &&
                featuredReviewsInstanceRef.current && (
                  <>
                    <ArrowReviews
                      left
                      onClick={(e: any) =>
                        e.stopPropagation() ||
                        featuredReviewsInstanceRef.current?.prev()
                      }
                      disabled={currentSlide === 0}
                    />

                    <ArrowReviews
                      onClick={(e: any) =>
                        e.stopPropagation() ||
                        featuredReviewsInstanceRef.current?.next()
                      }
                      disabled={
                        currentSlide ===
                        featuredReviewsInstanceRef.current.track.details.slides
                          .length -
                          1
                      }
                    />
                  </>
                )}
            </section>
          </div>
        </>
      )}
    </div>
  )
}

const renderBlock = (
  { __component, ...data }: any,
  index: any,
  products = null,
  albums = null
) => {
  let Block
  switch (__component) {
    case 'blocks.hero':
      Block = Hero
      break
    case 'blocks.featured-video':
      Block = FeaturedVideo
      break
    case 'blocks.50-50':
      return (
        <div data-aos="fade-up" data-aos-delay="500" className="block-50_50 max-w-[960px] mx-auto">
          <div
            dangerouslySetInnerHTML={{
              __html: data.Content_left,
            }}
          ></div>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: data.Content_right,
            }}
          ></div>
        </div>
      )
      break
    case 'blocks.image-gallery':
      return <Gallery key={`block-index-${index}`} albums={albums}></Gallery>
      break
    case 'blocks.product-gallery':
      return <ProductGallery key={`block-index-${index}`} products={products} />
      break
  }

  return Block ? <Block key={`block-index-${index}`} {...data} /> : null
}

Home.Layout = Layout
